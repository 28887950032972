import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/platform-web3/auth.service';
import { AuthInfo, UserState } from 'src/app/platform-web3/user.state';
import { AuthQRCodeComponent } from '../auth-qrcode/auth-qrcode.component';
import { CopyrightContentComponent } from '../header-bar/terms/copyright-content.component';
import { PrivacyContentComponent } from '../header-bar/terms/privacy-content.component';
import { ServiceContentComponent } from '../header-bar/terms/service-content.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingComponent implements OnInit, OnDestroy {

  unSubscribe$ = new Subject();
  authInfo: AuthInfo = {} as AuthInfo;

  advanceUser = false;
  powerBackendUser = false;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private auth: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(I18NEXT_SERVICE) private i18NextSrv: ITranslationService
  ) { }

  ngOnInit(): void {
    this.store.select(UserState.authInfo).pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe(u => {
      this.authInfo = (u ?? {}) as AuthInfo

      const account = this?.authInfo?.account ?? '';
      this.powerBackendUser = [
        'yaoming.huang@ischool.com.tw'
      ].indexOf(account) >= 0;
      this.changeDetectorRef.detectChanges();
    });

    this.i18NextSrv.events.languageChanged.subscribe(lang => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.advanceUser = event.shiftKey && event.altKey;
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    // this.advanceUser = event.shiftKey;
  }

  openModal(content: any, config: MatDialogConfig) {
    this.dialog.open(content, config);
  }

  /** QR Code */
  async openQRModal() {
    this.openModal(AuthQRCodeComponent, {
      width: '280px',
      autoFocus: false
    });
  }

  /** 登出帳號 */
  logoutAccount(list: string[]) {
    this.auth.logout(list);
  }

  /** 登出 */
  logout() {
    this.logoutAccount([]);
  }

  openServiceContent() {
    window.open('https://www.ischool.com.tw/terms.html#service', '_blank', 'noopener,noreferrer');
  }

  openPrivacyContent() {
    window.open('https://www.ischool.com.tw/terms.html#privacy', '_blank', 'noopener,noreferrer');
  }

  openCopyRightContent() {
    window.open('https://www.ischool.com.tw/terms.html#copyright', '_blank', 'noopener,noreferrer');
  }

  btnDomainManager() {
    const target = `https://auth.ischool.com.tw/default/domainmanage.php`;
    window.open(`/auth/linkout?redirect_url=${encodeURIComponent(target)}`);
  }
}
